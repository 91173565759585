<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-new-table
          v-loading="loading"
          :data="tableData"
          pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :current-page.sync="current"
          :total="totalNum"
          :page-size="10"
          @size-change="handleSizeChange"
          @ayl-current-change="handleCurrentChange"
          @getSearchPramas="getSearchPramas"
        >
          <template #after>
            <el-button style="margin-right: 18px" type="primary" @click="onSearch"
              >查询</el-button
            >
            <el-link type="primary" @click="dialogExport = true">查询数据导出</el-link>
          </template>
        </ayl-new-table>
        <!--导出-->
        <el-dialog
          title="选择导出的数据"
          :visible.sync="dialogExport"
          width="540px"
          center
        >
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
            <el-checkbox
              :label="item.index"
              v-for="(item, idx) in checkList"
              :key="idx"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="exportExcel" :loading="btnLoading"
              >确 定</el-button
            >
            <el-button @click="dialogExport = false">取 消</el-button>
          </span>
        </el-dialog>
        <!-- 查看图片 -->
        <el-dialog title="图片" :visible.sync="driverDialogVisible" width="1000px" center>
          <div
            class="order-img avatar"
            v-for="(item, index) in imageInfoVOS"
            :key="index"
          >
            <el-image
              :preview-src-list="imgSrcList"
              :src="imageInfoVOS[index]"
              fit="cover"
              style="width: 100%; height: 100%; border-radius: 4px"
              @click="onPreview(index)"
            >
            </el-image>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../../../utils/mixins";
import newTableMixins from "../../../utils/new-table-mixins";
export default {
  mixins: [mixins.$exportCheckAll, newTableMixins],
  data() {
    return {
      nav: [
        { name: "业务报表", path: "" },
        { name: "状态报表", path: "" },
      ],
      imgSrcList: [],
      dialogExport: false,
      driverDialogVisible: false,
      imageInfoVOS: [],
      checkList: [],
      checkIndex: [],
      btnLoading: false,
      tableData: {
        api: this.$api.statusDriver,
        query: {
          startTime: null,
          endTime: null,
          orderStatus: "对账前修改",
          orderType: null,
          queryContent: null,
        },
        searchData: [
          {
            title: "日期",
            type: "dateRange",
            model: ["startTime", "endTime"],
          },
          {
            type: "select",
            title: "业务类型",
            model: "orderType",
            placeholder: "请选择",
            option: this.$enums.OrderNewType.list,
          },
          {
            type: "select",
            title: "历史订单状态",
            model: "orderStatus",
            clearable: true,
            placeholder: "请选择",
            option: this.$enums.OrderStatus_z.list,
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "车牌号",
          },
        ],
        columns: [
          {
            title: "客户名称",
            key: "partnerName",
            fixed: "left",
            showTooltip: true,
          },
          {
            title: "结算客户",
            key: "partnerAccountName",
            fixed: "left",
            showTooltip: true,
          },
          {
            title: "运输日期",
            width: "120px",
            filter: "str2ymd",
            key: "transportTime",
            fixed: "left",
          },
          {
            title: "提货车号",
            width: "100px",
            fixed: "left",
            key: "plateNumber",
          },
          {
            title: "驾驶员",
            width: "100px",
            key: "driverName",
            fixed: "left",
          },
          {
            title: "业务类型",
            width: "100px",
            render: (h, ctx) => {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color:
                        ctx.row.orderType === "直拨"
                          ? this.$config.color_z
                          : ctx.row.orderType === "内转"
                          ? this.$config.color_n
                          : this.$config.color_w,
                    },
                  },
                  ctx.row.orderType || "- -"
                ),
              ]);
            },
          },
          {
            title: "起运地",
            key: "sendAddressDetail",
            showTooltip: true,
          },
          {
            title: "目的地",
            key: "receiveAddressDetail",
            showTooltip: true,
          },
          {
            title: "当前订单状态",
            width: "120px",
            key: "orderStatus",
          },
          {
            title: "订单编号",
            width: "160px",
            key: "orderId",
          },
          {
            title: "图片",
            width: "70px",
            render: (h, ctx) => {
              return h("span", [
                h(
                  "el-button",
                  {
                    props: {
                      type: "text",
                      disabled: ctx.row.imageInfoVOS.length > 0 ? false : true,
                    },
                    on: {
                      click: () => this.driver(ctx.row),
                    },
                  },
                  ctx.row.imageInfoVOS.length
                ),
              ]);
            },
          },
          {
            title: "操作",
            width: "78px",
            align: "center",
            render: (h, ctx) => {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: () => {
                        this.onDetails(ctx.row);
                      },
                    },
                  },
                  "订单详情"
                ),
              ]);
            },
          },
        ],
      },
    };
  },
  methods: {
    // 依次查看大图
    onPreview(index) {
      let arr1 = this.imageInfoVOS.filter((arrItem, arrIndex) => index <= arrIndex);
      let arr2 = this.imageInfoVOS.filter((arrItem, arrIndex) => index > arrIndex);
      let arr3 = arr2.sort((a, b) => {
        b - a;
      });
      this.imgSrcList = [...arr1, ...arr3];
    },
    //查看图片
    async driver(val) {
      this.driverDialogVisible = true;
      this.imageInfoVOS = val.imageInfoVOS.map((item) => {
        return item.imageUrl[0];
      });
    },
    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        const url = await this.$api.statusDriverExcel({
          params: {
            startTime: this.tableData.query.startTime,
            endTime: this.tableData.query.endTime,
            orderStatus: this.tableData.query.orderStatus,
            orderType: this.tableData.query.orderType,
            queryContent: this.tableData.query.queryContent,
          },
          excelKey: "StatusOrderReport",
          indexList: this.checkIndex,
        });
        window.open(url);
        this.dialogExport = false;
      } catch (e) {}
      this.btnLoading = false;
    },
    // 订单详情
    onDetails(row) {
      this.$router.push({
        path: "/business-report/search-derails",
        query: {
          orderId: row.orderId,
        },
      });
    },
  },
  mounted() {
    // 获取导出列表;
    this.$api
      .GetExcelExportKeyAndColumn({
        excelKey: "StatusOrderReport",
      })
      .then((res) => {
        this.checkList = res.chsList;
        this.checkIndex = this.checkList.map((e) => {
          return e.index;
        });
      });
  },
};
</script>
<style lang="css" scoped>
.avatar {
  width: 307px;
  height: 150px;
}
.order-img:nth-child(3n) {
  margin-right: 0;
}
::v-deep .el-image-viewer__close {
  color: #fff;
}
::v-deep .el-image-viewer__next,
::v-deep .el-image-viewer__prev {
  border: 3px solid #fff;
}
</style>
<style lang="sass" scoped></style>
